.tab-item {
  padding: 10px 20px;
  cursor: pointer;
  align-items: center;
  text-align: center;
}
.tab-item.active {
  color: red;

  cursor: pointer;
  border-bottom: 2px solid red;
}
/* styles.css */

/* Other styles */

/* Media query for screens up to 768px wide (tablet and below) */
@media (max-width: 768px) {
  /* Adjust form container width */
  .z-50 {
    width: 90%;
    max-width: 90%;
    margin: auto;
  }

  /* Adjust form input width */
  input[type="text"],
  select {
    width: 100%;
  }

  /* Adjust form button width */
  button {
    width: 100%;
  }

  /* Adjust form label alignment */
  .mb-1 {
    text-align: left;
  }

  /* Adjust form icon size */
  .fa-times-icon {
    font-size: 20px;
  }

  /* Adjust form document list */
  .flex.space-x-5 {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .flex.space-x-5 > span {
    margin-top: 0.5rem;
  }

  .flex.space-x-5 > li {
    margin-bottom: 0.5rem;
  }
}

