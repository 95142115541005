.tab-item {
    padding: 10px 20px;
    cursor:pointer;
    align-items: center;
    text-align: center;
  }
  .tab-item.active {
    color: red; 
    cursor: pointer;
    border-bottom: 2px solid red;
  }
